import {useState, useEffect, useCallback } from 'react';
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { ToastContainer, toast } from 'react-toastify';
import { IQuoteImage } from '../Interfaces/IQuoteImage';
import Header from '../components/Header';
import {MdDelete} from 'react-icons/md'
import { confirmAlert } from 'react-confirm-alert';
import ImageViewer from 'react-simple-image-viewer';
import TypesList from '../components/TypesList';
import $ from 'jquery';

function FileUploadMultiple() {

	const [loading, setLoading] = useState<boolean>(false);
	const [fileList, setFileList] = useState<FileList | null>(null);
	const [filenamesList, setFilenamesList] = useState<IQuoteImage[]>([])
	const [show, setShow] = useState(false)
	const [error, setError] = useState(false) 
	const [showUpload, setShowUpload] = useState(false) 
	const [message, setMessage] = useState('') 
	const [imageType, setImageType] = useState('')
	const [currentImage, setCurrentImage] = useState(0);
  	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [currentQuote, setCurrentQuote] = useState('');

	const handleFileChange = (e) => {
		setFileList(e.target.files);
		setShow(false)
		setShowUpload(true)
	};
	
	const openImageViewer = useCallback((index) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	  }, []);
	
	  const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	  };

	const populate = () => {
		const endpoint = process.env.REACT_APP_BASE_URL + '/api/getQuotesImage.php';
		fetch(endpoint, {
			method: 'POST',
			body: JSON.stringify({}),
		})
		.then((res) => res.json())
		.then(response => {     
			if(response.action === "success"){
				setFilenamesList(response.results);
			}
		})
		.then(() => {
            setLoading(false)
            const caller = setInterval(
                () => {
                    
                    if($('#file_export').length > 0 ){
                        
                        if(!$.fn.dataTable.isDataTable("#file_export")){
                            $('#file_export').DataTable({
								order: [[2, 'desc']],
								pageLength: 50
							});
                            clearInterval(caller)                            
                        }
                    }
                },
                100
            )
        })
		.catch(err => {
			console.log(err);
		});
	}

	useEffect(() => {
		populate()
	}, [filenamesList.length]);

	const handleDelete = (e:React.FormEvent, id : string) => {
        e.preventDefault();

        confirmAlert({
            title: 'Deletion confirmation',
            message: 'Are you sure you want to delete this image?',
            buttons: 
            [
                {
                    label: 'Yes',
                    onClick: () => {
                        const endpoint = process.env.REACT_APP_BASE_URL + '/api/deleteQuoteImage.php';
                        fetch(endpoint, {
                            "method": "POST",
                            "body": JSON.stringify({id : id})
                        })
                        .then(response => response.json())
                        .then(response => {     
                            if(response.action === "failed"){
                                toast.error(response.msg, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    theme: "colored"
                                });
                            }else{
                                toast.success(response.msg, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    theme: "colored"
                                });
								
								setTimeout(() => {
                                    window.location.reload();
                                }, 2000);                
                            }
                        })
                        .catch(data => {
                            toast.error(data.msg, {
                                position: toast.POSITION.TOP_RIGHT,
                                theme: "colored"
                            });
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => void{},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        });    
    }


  	const handleUploadClick = () => {
		if (!fileList) {
			return;
		}

		//  Create new FormData object and append files
		const data = new FormData();
		const endpoint = process.env.REACT_APP_BASE_URL + '/api/saveQuoteImage.php';

		files.forEach((file, i) => {
			//data.append(`file-${i}`, file, file.name);
			data.append('quoteFile', file, file.name);
			data.append('filename', file.name);
			data.append('imageType', imageType);
			fetch(endpoint, {
				method: 'POST',
				body: data,
			})
			.then((res) => res.json())
			.then(response => {     
				if(response.action === "failed"){
					setShow(true)
					setTimeout(function() { setShow(false); }, 3000);
					setError(true)
					setMessage(response.msg)
				}else{
					setShow(true)
					setTimeout(function() { setShow(false); }, 3000);
					setError(false)
					populate()
					setShowUpload(false)
					setMessage(response.msg)
				}
			})
			.catch(err => {
				console.log(err);
			});
		});       
  	};

	const bold = {
		fontWeight : 600
	}
  	//  files is not an array, but it's iterable, spread to get an array of files
  	const files = fileList ? [...fileList] : [];

	return (
		<>
		<Header page="upload" />
		<div className="container">
			<ToastContainer />
			<div className="upload-container">
				<div>
					<input accept="image/x-png, image/jpeg, image/gif" type="file" onChange={(e) => handleFileChange(e)} multiple />
				</div>
				{
					showUpload ? 
					<>
					<div className="images-container">
						{
						files.map((file, i) => (
							<div key={i} style={{ margin:'10px' }}>
								<img src={URL.createObjectURL(file)} style={{width:"150px"}}/>
							</div>
							))
						}
					</div>
					<div className="col">
                        <div className="form-group">
                            <label style={bold}>Type </label>
                            <div className="controls">
                                <select
                                    name="minheight"
                                    id="minheight"
                                    className="form-select mb-3" 
                                    onChange={(e) => setImageType(e.target.value)} 
                                    value={imageType}>
										{
											TypesList.map((item) => 
												<option value={item.value}>{item.title}</option>
											)
										}
                                        
                                </select>
                            </div>
                        </div>
                    </div>
					<div><button type="button" id="save" onClick={handleUploadClick} className="btn btn-primary">Save</button></div>
					</>
					: ''
				}
			</div>
		{
		show ? 
			error ? 
			<div className="alert alert-danger" role="alert">
				{message}
			</div>
			: 
			<div className="alert alert-success" role="alert">
				{message}
			</div>
			: ''
		}
		{
		filenamesList.length > 0 ?
		<div className="table-responsive">  
			<table id="file_export" className="table table-striped table-bordered display small">
				<thead>
					<tr>
						<th>Image</th>
						<th>Type</th>
						<th>Created At</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{filenamesList.map((item, i) => (
						<tr key={i}>
							<td>
								{/* <img src={process.env.REACT_APP_BASE_URL + '/temp/' + item.image} style={{width:"150px"}}/> */}
								<img
									src={process.env.REACT_APP_BASE_URL + '/temp/' + item.image}
									onClick={ () => openImageViewer(i) }
									width="150"
									key={ i }
									style={{ cursor:'pointer' }}
									alt=""
								/>
								{isViewerOpen && (
									<ImageViewer 
									src={ filenamesList.map((item) => process.env.REACT_APP_BASE_URL + '/temp/' + item.image)}
									currentIndex={ currentImage }
									disableScroll={ false }
									closeOnClickOutside={ true }
									onClose={ closeImageViewer }
									/>
								)}
							</td>
							<td>
								{item.type}
								{/* <select
                                    name="minheight"
                                    id="minheight"
                                    className="form-select mb-3" 
                                    onChange={(e) => UpdateQuoteType(e.target.value)} 
                                    value={item.type}>
									<option value="quotes">Quotes</option>
									<option value="memes">Memes</option>                               
								</select> */}
							</td>
							<td>
								{item.createdAt}
							</td>
							<td><button type="button" className="btn btn-danger" onClick={(e) => handleDelete(e, item.id)}><MdDelete/></button></td>
						</tr>
						))
					}
				</tbody>
				<tfoot>
					<tr>
						<th>Image</th>
						<th>Type</th>
						<th>CreatedAt</th>
						<th></th>
					</tr>
				</tfoot>
			</table>
		</div>
		: ''
		}
	</div>
	</>
	);
}

export default FileUploadMultiple;