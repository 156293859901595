import Header from "../components/Header";
import { useState, useEffect } from "react";
import {Quote} from '../Interfaces/IQuote'
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import {AiFillEdit} from 'react-icons/ai'
import {MdDelete} from 'react-icons/md'
import { Blob } from 'buffer'
import $ from 'jquery';

interface Props{
}

const AddQuote:React.FC<Props> = () => {

    const QUOTE_LENGTH = 5;
    const SOURCE_LENGTH = 5;
    const [list, setList] = useState<Quote[]>([]);
    const [quote, setQuote] = useState<string>('');
    const [source, setSource] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [id, setId] = useState<string>('');
    const bold = {
        fontWeight : '600'
    }
   
    const populate = () => {
        const url = process.env.REACT_APP_BASE_URL + '/api/quotesList.php';
        setLoading(true)
        fetch(url, {
            method : "POST",
            body : JSON.stringify({})
        })
        .then(response => response.json())
        .then(data => {
            setList([...data.results])
        })
        .then(() => {
            setLoading(false)
            const caller = setInterval(
                () => {
                    if($('#file_export').length > 0 ){
                        if(!$.fn.dataTable.isDataTable("#file_export")){
                            $('#file_export').DataTable();
                            clearInterval(caller)                            
                        }
                    }
                },
                100
            )
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }

    const handleEdit = (e:React.FormEvent, quote: string, source: string, id : string) => {
        setId(id);
        setQuote(quote);
        setSource(source);
    }

    const handleDelete = (e:React.FormEvent, id : string) => {
        e.preventDefault();
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/deleteQuote.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify({id : id})
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                populate()           
            }
        })
        .catch(data => {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
        });
    }

    const reset = () => {
        setQuote('');
        setSource('');
    }


    useEffect(() => {
        populate()
    }, [list.length]);


    const handleSave = (e:React.FormEvent) => {
        e.preventDefault();
        
        if($('#quote').val() < QUOTE_LENGTH){
            $('#quote').removeClass('is-valid');
            $('#quote').addClass('is-invalid');
            return false
        }

        if($('#source').val() < SOURCE_LENGTH){
            $('#source').removeClass('is-valid');
            $('#source').addClass('is-invalid');
            return false
        }
        
        const data = {
            quote : quote,
            source : source,
            id : id
        }

        const endpoint = process.env.REACT_APP_BASE_URL + '/api/saveQuote.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }else{
               
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT
                });
                reset()
                populate()
            }
        })
        .catch(err => {
            console.log(err);
        });
    };
    
  
    const handleQuote = (e:React.FormEvent, quote:string) => {
        const target = e.target as HTMLTextAreaElement;
        setQuote(quote)
        if(quote.length < QUOTE_LENGTH){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        }
    }

    const handleSource = (e:React.FormEvent, source:string) => {
        const target = e.target as HTMLTextAreaElement;
        setSource(source)
        if(source.length < SOURCE_LENGTH){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
        }else{
            target.classList.remove('is-invalid');
            target.classList.add('is-valid');
        }
    }

    return (
        <div>
            <ToastContainer />
            <Header page="add-quote"/>
            <div className="container small">
                <form style={{ marginTop:'30px' }}>
                    <div className="container-edit">
                        <div className="edit-item" style={{ width:'100%',margin:'10px' }}>
                            <div className="form-group mb-3">
                                <label className="required" style={bold}>Quote</label>
                                <div className="controls">
                                    <textarea rows={1} className="form-control small" id="quote" value={quote} onChange={(e) => handleQuote(e, e.target.value)}></textarea>
                                    <div className="invalid-feedback">The Quote field is required (Min {QUOTE_LENGTH} characters)</div>
                                    <div className="valid-feedback"></div>
                                </div>
                            </div>
                        </div>

                        <div className="edit-item" style={{ width:'100%',margin:'10px' }}>
                            <div className="form-group mb-3">
                                <label className="required" style={bold}>Source</label>
                                <div className="controls">
                                    <textarea rows={1} className="form-control small" id="source" value={source} onChange={(e) => handleSource(e, e.target.value)}></textarea>
                                    <div className="invalid-feedback">The Source field is required</div>
                                    <div className="valid-feedback"></div>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <div className="row justify-content-center">
                        <div className="col-md-1 col-md-offset-1">
                            <button type="button" id="save" onClick={handleSave} className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </form>
                {
                loading ? '' : 
                list.length > 0 ? 
                <div className="table-responsive">  
                    <table id="file_export" className="table table-striped table-bordered display small">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Quote</th>
                                <th>Source</th>
                                <th>Created date</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            list.map((item, i) => (
                                <tr key={i} >
                                    <td>{i+1}</td>
                                    <td>{item.quote}</td>
                                    <td>{item.source}</td>
                                    <td>{item.createdAt}</td>
                                    <td><button type="button" className="btn btn-info"><span onClick={(e) => handleEdit(e, item.quote, item.source, item.id)}><AiFillEdit /></span></button></td>
                                    <td><button type="button" className="btn btn-danger"><span onClick={(e) => handleDelete(e, item.id)}><MdDelete/></span></button></td>
                                </tr>              
                            ))    
                        }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Id</th>
                                <th>Quote</th>
                                <th>Source</th>
                                <th>Created date</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            : 'No result found'
            }
            </div>
        </div>
    )
}

export default AddQuote