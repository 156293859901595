import React from 'react'
import Header from '../components/Header'
import Gallery from '../components/Gallery'

const Listing = () => {
  return (
    <div>
        {/* <Header page="listing"/> */}
        <Gallery />
    </div>
  )
}

export default Listing