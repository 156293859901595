const TypesList = [
    {
        "title" : "",
        "value" : ""
    },
    {
        "title" : "Quote",
        "value" : "quote"
    },
    {
        "title" : "Memes",
        "value" : "memes"
    }];

export default TypesList;